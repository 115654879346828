import {Component, OnInit} from '@angular/core';
import {EcoleService} from "../../service/function/ecole.service";
import {GestionService} from "../../service/function/gestion.service";
import {PreinscriptionModel} from "../../database/models/preInscription.service";
import {preinscriptionCtrl} from "../../database/controllers/preInscriptionCtrl.service";
import {HttpClient} from "@angular/common/http";
import {_SERVEROOT} from "../../routes/server-root";
import {HelpersService} from 'src/app/helper/helper.service';

declare let $: any;

@Component({
  selector: 'app-preinscription',
  templateUrl: './preinscription.component.html',
  styleUrls: ['./preinscription.component.css']
})
export class PreinscriptionComponent implements OnInit {
  selectedFile: File;
  arrayFile: any = [];
  dataSexe: any = [{code: "M", name: "Masculin"}, {code: "F", name: "Féminin"}];
  messaError: string;
  LastY: string;
  Lastmastricule: string;
  InfoeleveSubmitted: boolean;
  idvFiliere: number = 0;
  dataSite: any = [{id: 1, label: "ABIDJAN"}, {id: 2, label: "LAKOTA"}];

  constructor(public helpe: HelpersService, public ecoleIfos: EcoleService,
              public gestion: GestionService, public preInscModel: PreinscriptionModel,
              private preInCrtl: preinscriptionCtrl, public http: HttpClient) {

  }

  ngOnInit() {
    $('.select2').select2();
    $("html, body").animate({scrollTop: 0}, "slow");
  }


  nextPage(eventPage) {
    let vError: boolean = false;
    let textError: string = "Veuillez selectionner :";

    switch (eventPage) {
      case 'infoEcole':


        $("#infoEcole").show();
        $("#infoEleve").hide();
        $("#infoDoc").hide();
        $("#e1").css('background', '#f6783a');
        $("#e2").css('background', 'darkgrey');
        $("#e3").css('background', 'darkgrey');
        break;
      case 'infoEleve':

        /*
        on verifie si les informations precedentes(ECOLE) on bien été saisie
        */

        if (this.preInscModel.id_site == "" || this.preInscModel.id_site == undefined) {
          textError = textError + "<br> Votre localité";
          vError = true;
        }
        if (this.preInscModel.code_ecole == "" || this.preInscModel.code_ecole == undefined) {
          textError = textError + "<br> Votre Ecole";
          vError = true;
        }
        if (this.preInscModel.id_cycle == "" || this.preInscModel.id_cycle == undefined) {
          textError = textError + "<br> Votre cycle";
          vError = true;
        }
        if (this.preInscModel.id_niveau == "" || this.preInscModel.id_niveau == undefined) {
          textError = textError + "<br> Votre niveau";
          vError = true;
        }
        if (this.idvFiliere == 1) {
          if (this.preInscModel.id_classe == "" || this.preInscModel.id_classe == undefined) {
            textError = textError + "<br> Votre filière";
            vError = true;
          }
        }

        if (vError) {
          $("#ErrorProcess").show();
          $("#ErrorProcess span").text("");
          $("#ErrorProcess span").html(textError);
          return;
        }
        $("#ErrorProcess").hide();
        $("#infoEleve").show();
        $("#infoEcole").hide();
        $("#infoDoc").hide();
        $("#e1").css('background', 'darkgrey');
        $("#e2").css('background', '#f6783a');
        $("#e3").css('background', 'darkgrey');
        break;
      case 'infoDoc':
        textError = "Veuiller renseigner"

        /*
           on verifie si les informations precedentes(ECOLE) on bien été saisie
           */

        if ($("#nom").val() == "" || $("#nom").val() == undefined) {
          textError = textError + "<br> Votre nom"
          vError = true;
        }
        if ($("#prenom").val() == "" || $("#prenom").val() == undefined) {
          textError = textError + "<br> Votre prenom"
          vError = true;
        }
        if ($("#contact").val() == "" || $("#contact").val() == undefined) {
          textError = textError + "<br> Votre contact"
          vError = true;
        }
        if (!this.preInscModel.sexe) {
          textError = textError + "<br> Votre genre"
          vError = true;
        }
        if ($("#nom_parent").val() == "" || $("#nom_parent").val() == undefined) {
          textError = textError + "<br> le nom du parent/tuteur"
          vError = true;
        }
        if ($("#prenom_parent").val() == "" || $("#prenom_parent").val() == undefined) {
          textError = textError + "<br> le prenom du parent/tuteur"
          vError = true;
        }
        if ($("#contact_p").val() == "" || $("#contact_p").val() == undefined) {
          textError = textError + "<br> Le contact du parent/tuteur"
          vError = true;
        }
        if ($("#fonction").val() == "" || $("#fonction").val() == undefined) {
          textError = textError + "<br> La fontion du parent/tuteur"
          vError = true;
        }


        if (vError) {
          $("#ErrorProcess").show();
          $("#ErrorProcess span").text("");
          $("#ErrorProcess span").html(textError);
          return;
        }
        $("#ErrorProcess").hide();


        $("#infoDoc").show();
        $("#infoEcole").hide();
        $("#infoEleve").hide();
        $("#e1").css('background', 'darkgrey');
        $("#e2").css('background', 'darkgrey');
        $("#e3").css('background', '#f6783a');
        break;
      default:
        console.log("No such day exists!");
        break;
    }
  }

  ValPreInscription() {
    if (this.arrayFile.length == 0) {
      $("#ErrorProcess").show();
      $("#ErrorProcess span").text("");
      $("#ErrorProcess span").html("Aucun document n'a été ajoutés.");
      return;
    }
    $("#id_loaderPre").show();
    // console.log(this.preInscModel);
    setTimeout(() => {
      this.preInCrtl.createPreInscrition(this.preInscModel)
        .then((data) => {
          $("#id_loaderPre").hide();
          if (data.statut) {
            this.Lastmastricule = data.matricule
            this.onClickFile(data.matricule, this.preInscModel.code_ecole, this.preInscModel.id_niveau);
            $("#process1").hide();
            $("#process2").hide();
            $("#finish").show();
          } else {
            this.messaError = data.message;
            $("#rrore").show();
          }
        })
        .catch((error) => {
          $("#id_loaderPre").hide();
        });
    }, 3000);
  }

  onFilechanged(event) {
    this.selectedFile = event.target.files[0];
    //this.arrayFile.push(event.target.files[0]);
  }

  onFileCilck() {
    if (this.preInscModel.title == "" || this.preInscModel.title == undefined) {
      $("#ErrorProcess").show();
      $("#ErrorProcess span").text("");
      $("#ErrorProcess span").html("Vous n'avez pas saisi le titre du document.");
      return;
    }
    $("#ErrorProcess").hide();
    this.arrayFile.push(
      {
        titre: this.preInscModel.title,
        dataFile: this.selectedFile
      });
    this.preInscModel.title = "";
  }

  deleteFileCilck(event) {
    this.arrayFile.splice(event, 1);
  }

  onClickFile(matricule, code_ecole, id_niveau) {
    /// console.log(this.arrayFile)
    for (let i = 0; i < this.arrayFile.length; i++) {
      const uploadData = new FormData();
      uploadData.append("id_annee", this.LastY);
      uploadData.append("matricule", matricule);
      uploadData.append("title", this.arrayFile[i].titre);
      uploadData.append("code_ecole", code_ecole);
      uploadData.append("id_niveau", id_niveau);
      uploadData.append("fichier", this.arrayFile[i].dataFile, this.arrayFile[i].dataFile.name);
      // uploadData.append("fichier", this.selectedFile, this.selectedFile.name);
      this.http.post(_SERVEROOT.addDossier, uploadData, {
        // this.http.post('http://localhost:81/pmo-consulting.ci/page/Page_view/upload_do_files', uploadData, {
        // reportProfress:true,
        // observe:'events'
      }).subscribe(event => {
        //  console.log(event)
      })
    }
  }

  lastYear(code_ecole?: any) {

    this.helpe.showLastYear(code_ecole).then((data) => {
      if (data.statut) {
        this.LastY = data.data.id_annee
      }
    })
      .catch((error) => {
        console.log("Erreur rencontrée.")
      })
  }

  /*
//******  impression du recu de pre-inscription
*/
  PrintRecu() {
    this.helpe.FichePre(this.Lastmastricule)
  }

  chooseEcole(e: any) {
    this.preInscModel.code_ecole = e;
    this.gestion.showAllCycle(e)
    this.lastYear(e);

    if (e != null && e != undefined && e != "") {
      let dVar = this.helpe.data_etab_Site.find((d: any) => d.code_ecole === e);
      if (parseInt(dVar.type_etablissement) == 1) {
        this.idvFiliere = 1;
        $("#idFiliere").show();
      } else {
        this.idvFiliere = 0;
        $("#idFiliere").hide();
      }
    }
  }

  chooseCycle(e: any) {
    this.preInscModel.id_cycle = e;
    this.gestion.searchNiveau(e, this.preInscModel.code_ecole)
  }

  chooseNiveau(e: any) {
    this.preInscModel.id_cycle = e;
    this.preInscModel.id_niveau = e;
    // this.gestion.searchNiveau(e, this.preInscModel.code_ecole)
    this.gestion.searchClasse(e, this.preInscModel.code_ecole)
  }

  chooseClasse(e: any) {
    this.preInscModel.id_classe = e;
  }

  chooseSite(e: any) {
    this.preInscModel.id_site = e;
    this.helpe.showList_etab_by_Site(e);
  }
}
