import { CookiesService } from "../cookies/cookies.service";
import { OnInit, Component } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from "@angular/common/http";
import { serverRoot, TOexpect, ErrorHttp, xchge } from "../interface/interface";
let serve = require('src/assets/data/serve.json');
declare let $: any;

export const INITIAL: serverRoot = {
    link: serve.api,
};

export const _SERVEROOT: any = {
    currentUrl: '',
    //redirectToLog: "http://localhost:2000/#/login",
    redirectToLog: "http://www.universitesaintpaul.org/mon-portail",

    /**
     * Les diferentes methodes pour gestion
     */

    connection: INITIAL.link + "admin/Auth/login",
    getLastYear: INITIAL.link + "admin/Auth/getLastYear",
    getAllYear: INITIAL.link + "admin/Auth/getAllYear",

    addCatCompo: INITIAL.link + "admin/Manage/addCatCompo",
    addCycle: INITIAL.link + "admin/Manage/addCycle",
    addNiveau: INITIAL.link + "admin/Manage/addNiveau",
    addClasse: INITIAL.link + "admin/Manage/addClasse",
    addTypeMatiere: INITIAL.link + "admin/Manage/addTypeMatiere",
    addMatiere: INITIAL.link + "admin/Manage/addMatiere",
    addComposition: INITIAL.link + "admin/Manage/addComposition",
    addScolarite: INITIAL.link + "admin/Manage/addScolarite",
    updateScolarite: INITIAL.link + "admin/Manage/updateScolarite",
    updateUser_by_class: INITIAL.link + "admin/Manage/updateUser_by_class",

    // getScolariteByLevel: INITIAL.link + "admin/Manage/getScolariteByLevel",
    getScolarite: INITIAL.link + "admin/Manage/getScolarite",
    getCatCompo: INITIAL.link + "admin/Manage/getAllCatCompo",
    // getCycle: INITIAL.link + "admin/Manage/getAllCycle",
    getCycle: INITIAL.link + "admin/Vitrine/getAllCycle",
    getAllTypeMatter: INITIAL.link + "admin/Manage/getAllTypeMatter",
    seach_MattersByTypeMatters: INITIAL.link + "admin/Manage/seach_MattersByTypeMatters",

    /**
     * Les diferentes methodes pour eleves
     */

    addProfessor: INITIAL.link + "admin/Professor/addProfessor",
    changeUserPass: INITIAL.link + "admin/Professor/changeUserPass",
    attribute_class: INITIAL.link + "admin/Professor/attribute_class",

    listProfessor: INITIAL.link + "admin/Users/listProfs",
    addUsers: INITIAL.link + "admin/Users/addUsers",
    listShowUsers: INITIAL.link + "admin/Users/listUsers",
    listUsers: INITIAL.link + "admin/Users/listUsersProfs",
    upUsers: INITIAL.link + "admin/Users/updateUser",
    addRegister: INITIAL.link + "admin/Users/addRegister",
    listRole: INITIAL.link + "admin/Users/listRole",
    getRegister_by_ets: INITIAL.link + "admin/Users/getRegister_by_ets",
    listUsers_compte: INITIAL.link + "admin/Users/listUsers_compte",
    activeCompte: INITIAL.link + "admin/Users/activeCompte",
    desableCompte: INITIAL.link + "admin/Users/desableCompte",
    // _test: INITIAL.linkW + "/Users/test",
    //_test: INITIAL.link + "admin/Users/test",


    addPreStudent: INITIAL.link + "admin/Students/addPreStudent",
    addDossier: INITIAL.link + "admin/Students/addDossier",
    addStudent: INITIAL.link + "admin/Students/addStudent",
    updateStudent: INITIAL.link + "admin/Students/updateStudent",
    listStudents: INITIAL.link + "admin/Students/listStudents",
    avatar: INITIAL.link + "admin/Students/avatar",
    listStendent_by_class: INITIAL.link + "admin/Students/listStendent_by_class",
    fichePrescription: INITIAL.link + "admin/Students/fichePrescription",
    av: INITIAL.link + "admin/Students/av",


    listNon_inscrit: INITIAL.link + "admin/Accounting/listNon_inscrit",
    list_inscrit: INITIAL.link + "admin/Accounting/list_inscrit",
    listInscris_day: INITIAL.link + "admin/Accounting/listInscris_day",
    getSolarite: INITIAL.link + "admin/Accounting/getSolarite",
    inscription: INITIAL.link + "admin/Accounting/inscription",
    versement: INITIAL.link + "admin/Accounting/versement",
    payment_day: INITIAL.link + "admin/Accounting/payment_day",
    list_payment: INITIAL.link + "admin/Accounting/list_payment",
    listeModalite: INITIAL.link + "admin/Accounting/listeModalite",
    remains_payBy_Student: INITIAL.link + "admin/Accounting/remains_payBy_Student",
    recuins: INITIAL.link + "admin/Accounting/ins",
    recuvers: INITIAL.link + "admin/Accounting/vers",


    listEstablishment: INITIAL.link + "admin/Register/listEstablishment",
    listAllsEstablishment: INITIAL.link + "admin/Register/listAllsEstablishment", // tous les etablissements
    listOneEstab: INITIAL.link + "admin/Register/infoEtablisment",
    updateEtabs: INITIAL.link + "admin/Register/updateEtabs",



    listeEtb_site: INITIAL.link + "admin/Vitrine/listeEtb_site",


    // Rechercher
    searchCompoByCylce: INITIAL.link + "admin/Manage/seach_compoByCycle",
    searchNiveauByCylce: INITIAL.link + "admin/Vitrine/seach_LevelByCycle",// Rechercher les niveaux en fonction du cycle
    // searchNiveauByCylce: INITIAL.link + "admin/Manage/seach_LevelByCycle",// Rechercher les niveaux en fonction du cycle
    searchClasseByNiveau: INITIAL.link + "admin/Manage/seach_ClassByLevel",  // Rechercher les classes en fonction du niveau
    listClass_by_user: INITIAL.link + "admin/Manage/listClass_by_user",  // Liste des classe par prof
    listUser_by_class: INITIAL.link + "admin/Manage/listUser_by_class",  // Liste des classe par prof
    listCompo: INITIAL.link + "admin/Manage/listCompo",  // Liste des classe par prof
    addProgram: INITIAL.link + "admin/Manage/addProgram",  // Liste des classe par prof
    listProgram: INITIAL.link + "admin/Manage/listProgram",  // Liste des classe par prof
    updateProgram: INITIAL.link + "admin/Manage/updateProgram",  // Liste des classe par prof


    nombreTypeNote: INITIAL.link + "admin/Invoice/nombreTypeNote",  // Liste des classe par prof
    typeNote: INITIAL.link + "admin/Invoice/typeNote",  // Liste des classe par prof
    ListNote: INITIAL.link + "admin/Invoice/ListNote",  // Liste des classe par prof
    bulletinTsem1: INITIAL.link + "admin/Invoice/bulletinTsem1",  // Liste des classe par prof

    addMoyenne_mat: INITIAL.link + "admin/Invoice/addMoyenne_mat",  // Liste des classe par prof
    addMoyenne_compo: INITIAL.link + "admin/Invoice/addMoyenne_compo",  // Liste des classe par prof
    ListMoyenne_compo: INITIAL.link + "admin/Invoice/ListMoyenne_compo",  // Liste des classe par prof
    ListMoyenne_mat: INITIAL.link + "admin/Invoice/ListMoyenne_mat",  // Liste des classe par prof


};

export const AUTHORIZATION: TOexpect = {
    token: 'xchge',
    ecoParam: 'xdata',
    tokenExp: 0,
    exp: false,
    pathNext: '',
    showRoot: ''
};


export const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
    })
};

export const handleError: ErrorHttp = {
    Error: "Error"
};

/*
 *creation de la class ShareData
 * Type @Component
 * Cette classe nous permettra d'initilisé tte les fonctions de notre service serverRoot
 * Initilisation depuis le @Component -- app.component.html ... au demarrage de l'application
 * selector : <serverRoot-cmp></serverRoot-cmp>
 */

@Component({ selector: 'serverRoot-cmp', templateUrl: './server.component.html' })
export class ShareData implements OnInit, xchge {
    public value: string;
    headers = new HttpHeaders();

    constructor(public cookie: CookiesService) {
        AUTHORIZATION.token = this.xchge({
            token: this.cookie.token,
            ide: this.cookie.code_ecole,
            idu: this.cookie.code_user,
        });
        AUTHORIZATION.ecoParam = {
            code_ecole: this.cookie.code_ecole,
            code_user: this.cookie.code_user,
            annee: this.cookie.annee,
            idannee: this.cookie.idannee
        };

        AUTHORIZATION.tokenExp = this.cookie.tokenExp;
    }

    /*
     * Interface xchge
     * Implementations de la class ShareData
     * Type Object @return value
     *Preparations de la requete Headers de HTTPCLIENT @angularModuleDefault
     */

    xchge(val) {
        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + val.token
            })
        };

        return httpOptions;
    }


    ngOnInit() {
    }
}



